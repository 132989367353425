import data from "../../../puzzles/data.csv";

const [columns, ...lines] = data.split('\r\n').map(v => v.split(','));
const randomRow = parseInt(Math.random() * lines.length);
const puzzleColumn = columns.indexOf('puzzle');
var selectedPuzzle = lines[randomRow][puzzleColumn].split('').map(v => v === '.' ? null : v);
// console.log("selectedPuzzle: ", typeof selectedPuzzle, selectedPuzzle)

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => ({
      ...obj,
      [item[key]]: item,
    }), initialValue);
};

var grids = convertArrayToObject(lines, 0);
console.log({grids});

export default {
  namespaced: true,
  state: {
    gridList: selectedPuzzle,
  },
  getters: {
    grids() {
      return grids;
    },
  },
  mutations: {
    updateGrid(state, { idx, value }) {
      state.gridList[idx] = { ...state.gridList[idx], wasFilled: true, value };
    },
  },
};
