import router from "../router";

export default {
  namespaced: true,
  state: {
    _isLoggedIn: true, // TODO: false
  },
  mutations: {
    loginSuccessfull(state) {
      state._isLoggedIn = true;
      router.push("/");
    },
    loginFailed(state) {
      state._isLoggedIn = false;
    },
    logoutSuccessfull(state) {
      state._isLoggedIn = false;
    },
    logoutFailed(state) {
      state;
    },
  },
  actions: {
    login: async (email, pass) => {
      const isValid = !!email && !!pass;
      return isValid;
    },
    logout: async () => {

    }
  },
  getters: {
    isLoggedIn(state) {
      return state._isLoggedIn;
    },
  },
};
