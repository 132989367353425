import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

var assert = require("assert");


const routes = [
  {
    path: "/",
    name: "Home",
    meta: { requiresAuth: true },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/2",
    name: "Home2",
    meta: { requiresAuth: true },
    component: () => import("../views/Home2.vue"),
  },
  {
    path: "/play",
    name: "Play",
    meta: { requiresAuth: true },
    component: () => import("../views/Play.vue"),
    children: [
      {
        path: "sudoku/:id?",
        name: "Sudoku",
        component: () => import("../games/Sudoku.vue")
      },
      {
        path: "nonogram/:id?",
        name: "Nonogram",
        component: () => import("../games/Nonogram.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/components",
    name: "Components",
    children: [
      {
        path: "sudoku",
        component: () => import("../games/Sudoku.vue"),
      },
      {
        path: "cell",
        component: () => import("../components/sudoku/Cell.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Sudoku by Jake the Snake";
  console.log(store.getters);

  const requiresAuth = to.meta.requiresAuth ?? false;
  const isLoggedIn = store.getters["auth/isLoggedIn"];

  assert(typeof requiresAuth === "boolean");
  assert(typeof isLoggedIn === "boolean");

  if (requiresAuth && isLoggedIn) {
    next();
  } else if (requiresAuth && !isLoggedIn) {
    next("/login");
  } else if (!requiresAuth && isLoggedIn) {
    next();
  } else {
    next();
  }
});

export default router;
