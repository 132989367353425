<template>
  <router-view :key="$route.fullPath" />
</template>

<style lang="scss">
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;

  margin: 0px;
  padding: 0px;
}

// body {
//   width: 100vw;
//   height: 100vh;
// }

#app {
  width: 100vw;
  height: 100vh;
}
</style>

<style>
.btn {
  background-color: #0e0e0e;
  color: #fff;
  border-radius: 8px;
  padding: 8px 12px;
  text-align: center;
  cursor: pointer;
}
</style>
