export default {
  namespaced: true,
  state: {
    games: [
      "Sudoku",
      "Nonogram"
    ],
  },
  getters: {
    games(state) {
      return state.games;
    },
  },
};
