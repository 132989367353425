import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate";

import vars from "./vars";
import auth from "./auth";
import sudoku from "./sudoku";

export default createStore({
  modules: {
    vars,
    auth,
    sudoku,
  },
  // plugins: [createPersistedState()]
});
